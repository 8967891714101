import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaign/types'
import { Pledge } from '@/services/api/pledge/types'
import config from '../../../../whiteLabel-config.json'

export type Domain = keyof typeof extraConfig

export const isWhiteLabel = (domain: Domain) =>
  !!config[domain as keyof typeof config]

export const getDomainFromSlug = (slug: string): Domain => {
  let domain: Domain = 'invest.angel.com'

  const entries = Object.entries(config)
  for (let i = 0; i < entries.length; i += 1) {
    const [entryDomain, slugs] = entries[i]
    if (slugs.includes(slug)) {
      domain = entryDomain as Domain
      break
    }
  }

  return domain
}

const davidHostnames = [
  'david.localhost:3000',
  'david.localhost',
  'david-reg-a.dev.angel.com',
  'david-reg-a.stg.angel.com',
  'thedavidmovie.com',
  'www.thedavidmovie.com',
]

const launchHostnames = [
  'launch.localhost:3000',
  'launch.localhost',
  'launch.movie',
  'www.launch.movie',
  'dev.launch.movie',
  'stg.launch.movie',
]

const fansInvestHostnames = [
  'fansinvest.localhost:3000',
  'fansinvest.localhost',
  'angel-reg-a.com',
  'www.angel-reg-a.com',
  'dev.angel-reg-a.com',
  'stg.angel-reg-a.com',
  'fansinvest.angel.com',
]

export const getDomainFromHostname = (hostname: string): Domain => {
  if (davidHostnames.includes(hostname)) {
    return 'thedavidmovie.com'
  }

  if (launchHostnames.includes(hostname)) {
    return 'launch.movie'
  }

  if (fansInvestHostnames.includes(hostname)) {
    return 'fansinvest.angel.com'
  }

  return 'invest.angel.com'
}

export const isAllowed = (obj: Campaign | Pledge, domain: Domain) => {
  const whiteLabelDomainConfig = config[domain as keyof typeof config] || null

  let slug = ''
  if ('slug' in obj) slug = obj.slug
  if ('campaign' in obj) slug = obj.campaign

  // If this is a white-labelled domain, make sure the campaign slug is included
  // in the array of slugs defined for the domain. If it's not a white-labelled
  // domain, then make sure the campaign slug isn't included in ANY domain configs.
  return whiteLabelDomainConfig
    ? whiteLabelDomainConfig.includes(slug)
    : !Object.values(config).flat().includes(slug)
}

const regexMap = {
  'invest.angel.com': /^\/home/,
  'thedavidmovie.com': /^\/david/,
  'launch.movie': /^\/launch/,
  'fansinvest.angel.com': /^\/fansinvest/,
}

// Removes the path rewrites from ./middleware.ts for Vercel platforms config
export const resolveCanonicalUrl = (path: string, domain: Domain) => {
  const regex = regexMap[domain]
  const cleanPath = path.replace(regex, '')

  return `https://${domain}${cleanPath}`
}

export const whiteLabelUtil = {
  isWhiteLabel,
  getDomainFromSlug,
  getDomainFromHostname,
  isAllowed,
  resolveCanonicalUrl,
}

const defaultConfig = {
  campaignSummary: '',
  canExpressInterest: true,
  captureChatbotConsent: false,
  cognitoFormsId: '',
  cognitoFormsKey: '',
  copyrightName: 'Angel Funding',
  ellisIslandClientId: 'angel_funding',
  footerDisclaimer: `This website is owned and operated by VAS Portal, LLC doing business as Angel Funding. All funding portal activities are conducted by VAS Portal, LLC dba Angel Funding. Any Reg A or Reg D offering listed on this website are managed by the broker dealer listed on the offering details.`,
  fundingCampaignDisclaimer: '',
  fundingCtaText: 'Back This Project',
  hideTimeRemaining: false,
  isChatbotAllowed: false,
  isExecutingSharePriceChange: false,
  pifUrl: '',
  priorRaisesDisclaimer: '',
  priorRaisesGoalAmount: 0,
  priorRaisesTitle: '',
  showAppDownloadLinks: false,
  showDiscountedGuildLink: false,
  showSecondaryCta: false,
  timeRemainingDescriptor: 'Left',
  title: 'Angel Funding',
  useAltProgressColor: false,
  usePriorRaisesLayout: false,
  zendeskKey: '361a1ab1-1121-48d7-a18c-c0ce46561cd0',
} as const

export const extraConfig = {
  'invest.angel.com': { ...defaultConfig },
  'thedavidmovie.com': {
    ...defaultConfig,
    ellisIslandClientId: 'david_reg_a',
    zendeskKey: '',
    cognitoFormsKey: 'E6D1XHWl6EK3GTVlg-eitw',
    cognitoFormsId: '4',
    timeRemainingDescriptor: 'Left*',
    fundingCampaignDisclaimer: `*$60,982,236 represents the current overall funding target for this project. DAVID is currently raising funding toward that goal in a Regulation A investment offering. DAVID is offering investments up to a maximum of $37.5 million in the current offering. More information about the current offering is available in the offering tab below.`,
    footerDisclaimer: `DISCLAIMER:

    THESE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO, AMONG OTHER THINGS, THE COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE FORWARDLOOKING STATEMENTS ARE BASED ON THE BELIEFS OF, ASSUMPTIONS MADE BY, AND INFORMATION CURRENTLY AVAILABLE TO THE COMPANY’S MANAGEMENT. WHEN USED IN THE OFFERING MATERIALS, THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,” “INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS ARE INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS. THESE STATEMENTS REFLECT MANAGEMENT’S CURRENT VIEWS WITH RESPECT TO FUTURE EVENTS AND ARE SUBJECT TO RISKS AND UNCERTAINTIES THAT COULD CAUSE THE COMPANY’S ACTUAL RESULTS TO DIFFER MATERIALLY FROM THOSE CONTAINED IN THE FORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE RELIANCE ON THESE FORWARD-LOOKING STATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY DOES NOT UNDERTAKE ANY OBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR CIRCUMSTANCES AFTER SUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.
    
    PLEASE NOTE INVESTORS IN THIS OFFERING WILL BE CLIENTS OF THE ISSUER AND NOT DALMORE GROUP, LLC (“DALMORE”), A REGISTERED BROKER-DEALER AND MEMBER FINRA/SIPC. DALMORE’S ROLE IN THE TRANSACTION IS TO FACILITATE BACK OFFICE AND REGULATORY FUNCTIONS RELATED TO THE REGULATION A TRANSACTION, AND ACTS ONLY AS THE BROKER/DEALER OF RECORD FOR THE OFFERING LISTED. DALMORE IS NOT PROVIDING INVESTMENT ADVICE OR RECOMMENDATIONS, OR LEGAL OR TAX ADVICE.
    
    THIS REG A INVESTMENT IS SPECULATIVE, ILLIQUID, AND INVOLVES A HIGH DEGREE OF RISK, INCLUDING THE POSSIBLE LOSS OF YOUR ENTIRE INVESTMENT. ALL INVESTORS SHOULD MAKE THEIR OWN DETERMINATION, WITH THE ASSISTANCE OF THEIR OWN FINANCIAL OR OTHER ADVISORS, AS TO WHETHER OR NOT TO MAKE ANY INVESTMENT, BASED ON THEIR OWN INDEPENDENT EVALUATION, ANALYSIS AND CIRCUMSTANCES.
    
    AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. THE SEC HAS QUALIFIED THAT OFFERING STATEMENT, WHICH ONLY MEANS THAT THE COMPANY MAY MAKE SALES OF THE SECURITIES DESCRIBED BY THE OFFERING STATEMENT. IT DOES NOT MEAN THAT THE SEC HAS APPROVED, PASSED UPON THE MERITS OR PASSED UPON THE ACCURACY OR COMPLETENESS OF THE INFORMATION IN THE OFFERING STATEMENT. THE OFFERING CIRCULAR THAT IS PART OF THAT OFFERING STATEMENT IS AT: https://www.sec.gov/Archives/edgar/data/1893768/000121465922013223/partiiandiii.htm

    BY: JT Sadler
    CRD 2817763
    Mar 11 2024`,
    title: 'The David Movie',
    usePriorRaisesLayout: true,
    priorRaisesDisclaimer: `DAVID is the offering investment in this Regulation A offering. By investing in this Regulation A offering you purchase a membership interest in the Slingshot USA LLC (the entity that owns DAVID) and become a member of the LLC and subject to its operating agreement. The minimum investment amount for this Reg. A round of funding is $99. For more detailed information about the Regulation A Offer please visit the Offering tab which provides the official investment documentation including the Offering Circular for this offering. Please note that investing in this Regulation A offering involves a high degree of risk including the possibility of the total loss of your investment, you should consult with your advisors prior to making an investment decision.`,
    campaignSummary: `DAVID will be a musical animated feature film that combines powerful biblical authenticity, inspired music, captivating storytelling, and superb production quality: it aims to stand alongside the best animation movies of all time.

Planned for a 2025 global cinema release, our prayer and passion is for this incredible story *of what God did through one faithful shepherd boy* to be one of the most enduring, influential movies for generations to come... a celebrated cinema event.

*“David’s story has resonated with people from both within and outside the faith community for millennia due to its ability to reach the broadest audience. Our vision is to make a movie and tell a story that will delight and inspire the broadest possible audience while staying authentic to the Bible’s account of how one man’s relationship with God liberated a nation and changed the course of history.”*

Phil Cunningham - Director`,
    priorRaisesGoalAmount: 6098223600,
    priorRaisesTitle: 'Overall project funding goal',
    captureChatbotConsent: true,
    isChatbotAllowed: true,
    showSecondaryCta: false,
    hideTimeRemaining: true,
    pifUrl: '',
    canExpressInterest: false,
    isExecutingSharePriceChange: false,
    useAltProgressColor: true,
  },
  'launch.movie': {
    ...defaultConfig,
    canExpressInterest: false,
    copyrightName: 'Angel',
    ellisIslandClientId: 'angel_reg_a',
    footerDisclaimer: `This website is owned and operated by Angel Studios. Angel Studios 010, Inc., a subsidiary of Angel Studios, Inc., is the issuer of this offering.\n\nInvestments in this offering involves a high degree of risk. Investors must be able to afford the loss of their entire investment. Investors should conduct their own due diligence, not rely on the financial assumptions or estimates displayed herein, and are encouraged to consult with a financial advisor, attorney, accountant, tax advisors, and any other professional that can help you to understand and assess the risks associated with any investment opportunity. Past performance of affiliated or analogous projects is not indicative of future results.\n\nSecurities are offered through Rialto Markets, LLC (“Rialto”) a registered broker-dealer and member FINRA/SIPC. Neither Rialto, nor any of its affiliates provide investment advice or make any investment recommendations to any persons, and no communication herein or in any other medium should be construed as such. Learn more about Rialto on [BrokerCheck](https://brokercheck.finra.org/firm/summary/283477).`,
    fundingCtaText: 'Invest Now',
    fundingCampaignDisclaimer: `*Fundraising amounts in the progress bar expressed in $1M milestones. Angel Studios 010, Inc., reserves the right to raise up to $5M for this offering as stated in the Offering Circular. People includes all persons who have submitted subscription materials. Amount raised is an estimate that includes subscriptions, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted. Days left is the estimated time until the offering is full based on current volume of investments.`,
    title: 'Angel',
    zendeskKey: '',
  },
  'fansinvest.angel.com': {
    ...defaultConfig,
    canExpressInterest: false,
    captureChatbotConsent: true,
    copyrightName: 'Angel Studios',
    ellisIslandClientId: 'angel_reg_a',
    footerDisclaimer: `This website is owned and operated by Angel Studios. The Regulation A offering listed on this website is managed by Rialto Markets, LLC, the broker dealer of record listed in the offering details.`,
    fundingCampaignDisclaimer: `*While this offering goal is listed as $5M, as stated in the Offering Circular, Angel Studios reserves the right to raise up to $20M. People includes all persons who have submitted subscription materials. Amount raised is an estimate that includes subscriptions, however not all such amounts have been accepted or closed, and some subscriptions may not be accepted.`,
    fundingCtaText: 'Invest in Angel',
    showAppDownloadLinks: true,
    showDiscountedGuildLink: true,
    title: 'Angel Studios',
    zendeskKey: '',
  },
} as const

export const resolveSiteAssets = (
  assets: ISiteAssetsFields,
  domain: Domain = 'invest.angel.com'
): SiteAssets => {
  return {
    ...assets,
    ...extraConfig[domain],
    domain,
    isWhiteLabel: whiteLabelUtil.isWhiteLabel(domain),
  }
}

export interface SiteAssets extends ISiteAssetsFields {
  campaignSummary: string
  canExpressInterest: boolean
  captureChatbotConsent: boolean
  cognitoFormsId: string
  cognitoFormsKey: string
  copyrightName: string
  domain: Domain
  ellisIslandClientId: string
  footerDisclaimer: string
  fundingCampaignDisclaimer: string
  fundingCtaText: string
  hideTimeRemaining: boolean
  isChatbotAllowed: boolean
  isExecutingSharePriceChange: boolean
  isWhiteLabel: boolean
  pifUrl: string
  priorRaisesDisclaimer: string
  priorRaisesGoalAmount: number
  priorRaisesTitle: string
  showAppDownloadLinks: boolean
  showDiscountedGuildLink: boolean
  showSecondaryCta: boolean
  timeRemainingDescriptor: 'Left' | 'Left*'
  useAltProgressColor: boolean
  usePriorRaisesLayout: boolean
  zendeskKey: string
}
